<template>
    <div>
        <div class="wpb_wrapper">
            <div class="zozo-parallax-header margin-bottom-30">
                <div class="parallax-header content-style-default">
                    <h2 class="parallax-title text-center" style="font-family: 'Cairo' !important;">
                        {{ $t("header.Gallery") }}</h2>
                </div>
            </div>
            <div id="zozo-portfolio-wrapper-1"
                class="zozo-portfolio-grid-wrapper zozo-isotope-grid-system default item-has-content">
                <div class="zozo-isotope-filters clearfix">
                    <ul class="zozo-smartmenu portfolio-tabs arrow-style-filter arrow-center text-center">
                        <li class="smartmenu-filter">
                           
                            <ul class="smart-sub-menu portfolio-sub-filter"
                                v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">
                                <li><a data-filter="*" @click="xxx(44411);getAll();cat_id=null;star=3;end=3;x=0"
                                        v-bind:class="[{'active' : active==44411 }]"><span>{{ $t("show_all") }}
                                        </span><span class="sub-arrow"></span></a></li>

                                <li v-for="item  in cats" :key="item"><a v-bind:class="[{'active' : active==item.id }]"
                                        data-filter="grid-cat-4" @click="xxx(item.id);getByCat(item.id);star=3;end=3;x=0"
                                        class=""><span>{{  $i18n.locale=='ar'?item.name:item.name_en}}</span></a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="zozo-isotope-wrapper no-gutter">

                <v-layout row wrap >
                     <v-flex xs12 sm4 md4 xl3  v-for="item in photo" :key="item" >
                           
                                                <div id="portfolio-120" 
                                                    class="portfolio-item isotope-post post-iso-w3 post-iso-h3 grid-cat-5  zozo-img-wrapper style-default"
                                                  
                                                    >

                                                    <div class="post-inside-wrapper margin-top-0 animate_when_almost_visible bottom-t-top start_animation"
                                                        data-delay="200">
                                                        <div class="portfolio-content  overlay-full overlay-center">
                                                            <div class="portfolio-image-overlay-wrapper">
                                                                <a :href="Url+'/art_img/'+item.img_file"  :data-caption="item.title"
                                                                    :data-fancybox="item.id">



                                                                    <div>

                                                                        <v-img class="img-responsive"
                                                                        

                                                                              :src="Url+'/art_img/thumb_new/'+item.img_file"
                                                                 :lazy-src="Url+'/art_img/small_thumb/'+item.img_file"
                                                                            
                                                                            width="560" height="385"
                                                                            :alt="item.title" />
                                                                    </div>


                                                                </a>
                                                            </div>
                                                            <div class="portfolio-overlay zoomin"></div>
                                                            <div class="portfolio-mask overlay-mask position-center">
                                                                <!-- <div class="portfolio-cat"><a title="children"
                                                                        :href="Url+'/art_img/'+item.img_file" :data-caption="item.title"
                                                                        :data-fancybox="item.id">{{item.photo_gallery_cats.name}}</a>
                                                                </div> -->
                                                                <div class="portfolio-title">
                                                                    <h4><a title="Become Volunteer"
                                                                            :href="Url+'/art_img/'+item.img_file" :data-caption="item.title"
                                                                            :data-fancybox="item.id">
                                                                           {{item.photo_gallery_cats.name}}
                                                                        </a></h4>
                                                                </div>
                                                                <ul class="overlay-buttons fade-down">
                                                                    <li><a :href="Url+'/art_img/'+item.img_file"
                                                                           :data-fancybox="item"  :data-caption="item.title"
                                                                            title="Become Volunteer"><i
                                                                                class="fas fa-search-plus"></i></a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                     </v-flex>
                </v-layout>

                </div>
                <div class="zozo-pagination-wrapper" style="opacity: 1;"></div>
<br><br>
<br>
                            <v-layout pt-3>
                <v-flex xs5></v-flex>
                <v-flex xs2>
                   

                       <v-btn  @click="more()"  small color="#12adef" style="
            position: relative;
            width:100px;
            color:#fff;
    float: right;
    bottom:19px;
    right: 19
px
;
        ">المزيد</v-btn>


                </v-flex>
                <v-flex xs5></v-flex>
            </v-layout>
            </div>
        </div>
    </div>
</template>
<style >
@media only screen and (max-width: 768px) {
#zozo_portfolio_1
{
    height:auto !important;
}

.zozo_portfolio_1
{
    position: initial !important;
}
  /* For mobile phones: */
  .portfolio-item{
 display: block !important;
 position: relative !important;
 left: 0px !important;
 right: 0x !important;
 top: 0px !important;
  }
}
    
</style>
<script>
    import Axios from "axios";

    export default {
        data() {
            return {
                cats: [],
                photo: [],
                leftx:0,
                cat_id:null,
                left_old:462,
                active: 44411,

                     star: 3,
                end: 3,
                
                x:0
            }
        },
        created() {
            this.getCats();
            this.getAll();

        },

        computed: {

        },
        methods: {

          more() {
                this.star = this.end;
                this.end = this.end + 6;
                
                if(this.cat_id==null)
                {
                      Axios.get('/photosGallery/GetPhotoByLimit/' + parseInt(this.end) + '/6')
                    .then(response => {
                        var i = 0;
                        this.$nextTick(() => {
                            for (i = 0; i < 6; i++) {
                                var obj = {};
                                obj = response.data.data[i];
                                this.photo.push(obj);

                                this.$forceUpdate();


                            }
                        });
                        this.$forceUpdate();



                    })

                }
                else
                {
                      Axios.get('/photosGallery/GetPhotoByLimitbyCatid/' + parseInt(this.end) + '/6/'+this.cat_id)
                    .then(response => {
                        var i = 0;
                        this.$nextTick(() => {
                            for (i = 0; i < 6; i++) {
                                var obj = {};
                                obj = response.data.data[i];
                                this.photo.push(obj);

                                this.$forceUpdate();


                            }
                        });
                        this.$forceUpdate();



                    })

                }

              

            },

            getAll() {
                this.loading = true;
                Axios.get("/photosGallery/getlimit/33", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.photo = res.data.data;
                        for(var i=0;i<this.photo.length;i++)
                        {

                            




                            if(i==0)
                            {
                                this.leftx=0; 
                            this.photo[i].width=0;

                            }
                            else{
                                   this.photo[i].width=0;
                                  this.leftx=this.leftx+462;
                                  if(i==3 || i==6 || i==9 || i==12 || i==15 || i==18 || i==21|| i==24 || i==27 || i==30 || i==33 || i==36 || i==39|| i==42 )
                                  {
                                      this.leftx=0;
                                  }
                            this.photo[i].width=this.leftx;
                            }


if (i % 2 == 0) {//and replace numm with string.length here
    return "even";
  } else {
     this.top+=300;
                           
                               this.photo[i].top= this.top;
  }

                        //        if(i<3)
                        //     {
                               
                        //             this.top=0;
                           
                        //        this.photo[i].top= this.top;

                        //     }
                        //     else if(i<6){
                                 
                        //   //         this.photo[i].width=0;
                        //             this.top=300;
                          
                        //      this.photo[i].top= this.top;
                        //     }

                        //      else if(i<9){
                                 
                        //   //       this.photo[i].width=0; 
                        //             this.top=600;
                          
                        //      this.photo[i].top= this.top;
                        //     }

                        //      else if(i<12){
                                 
                        //          this.top= 900;
                        //            // this.photo[i].width=0;
                          
                        //      this.photo[i].top= this.top;
                        //     }

                        //      else if(i<15){
                                 
                        //          this.top= 1200;
                        //         //    this.photo[i].width=0;
                          
                        //      this.photo[i].top= this.top;
                        //     }


                        //      else if(i<18){
                                 
                                
                        //             this.photo[i].width=0;
                          
                        //      this.photo[i].top= this.top;
                        //     }

                        //      else if(i<21){
                                 
                                
                        //             this.photo[i].width=0;
                          
                        //      this.photo[i].top= this.top;
                        //     }

                        //      else if(i<24){
                                 
                                
                        //             this.photo[i].width=0;
                          
                        //      this.photo[i].top= this.top;
                        //     }





                            
                           
                        }

                       

                    })
                    .catch(() => {
                        this.loading = false;
                    });

            },
            getByCat(item) {
                this.loading = true;
                this.cat_id=item;
                Axios.get("/photosGallery/GetByCat/" + item, {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.photo = res.data.data;
                          for(var i=0;i<this.photo.length;i++)
                        {
                             if(i==0)
                            {
                                this.leftx=0; 
                            this.photo[i].width=0;

                            }
                            else{
                                   this.photo[i].width=0;
                                  this.leftx=this.leftx+462;
                                  if(i==3 || i==6 || i==9 || i==12 || i==15 || i==18 || i==21|| i==24 || i==27 || i==30 || i==33 || i==36 || i==39|| i==42 )
                                  {
                                      this.leftx=0;
                                  }
                            this.photo[i].width=this.leftx;
                            }


                               if(i<3)
                            {
                               
                                    this.top=0;
                           
                               this.photo[i].top= this.top;

                            }
                            else if(i<6){
                                 
                          //         this.photo[i].width=0;
                                    this.top=300;
                          
                             this.photo[i].top= this.top;
                            }

                             else if(i<9){
                                 
                          //       this.photo[i].width=0; 
                                    this.top=600;
                          
                             this.photo[i].top= this.top;
                            }

                             else if(i<12){
                                 
                                
                                    this.photo[i].width=0;
                          
                             this.photo[i].top= this.top;
                            }

                             else if(i<15){
                                 
                                
                                    this.photo[i].width=0;
                          
                             this.photo[i].top= this.top;
                            }


                             else if(i<18){
                                 
                                
                                    this.photo[i].width=0;
                          
                             this.photo[i].top= this.top;
                            }

                             else if(i<21){
                                 
                                
                                    this.photo[i].width=0;
                          
                             this.photo[i].top= this.top;
                            }

                             else if(i<24){
                                 
                                
                                    this.photo[i].width=0;
                          
                             this.photo[i].top= this.top;
                            }

                           
                        }


                    })
                    .catch(() => {
                        this.loading = false;
                    });

            },
            xxx(item) {

                this.active = item

            },
            getCats() {

                Axios.get("/photosGalleryCats", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.cats = res.data.data;
                        // console.log(res.data);
                    })
                    .catch(() => {
                        this.loading = false;
                    });

            },
        },
    }
</script>